import React from 'react';
import './Timeline.css';
import FadeInDiv from './FadeInDiv';

const timelineData = [
  {
    year: '2016 - University of Dundee',
    event: 'Enrolled in Applied Computing BsC',
    image: 'Images/UOD.png',
  },
  {
    year: '2018 - Agile Team Project',
    event: 'Healthdome: "SkyScanner" for US Healthcare prices.',
    image: 'Images/healthdome1.png',
    morelink: 'https://zeno.computing.dundee.ac.uk/2019-projects/team2/site/healthdometrial.php',
  },
  {
    year: '2019 - Final Research Project',
    event: 'Teaching Virtual Reality in Music Dissertation',
    image: 'Images/mvr.png',
  },
  {
    year: '2020 - Graduation',
    event: 'Graduated from the University of Dundee 2:1 With Honors',
    image: 'Images/covidgrad.png',
  },
  {
    year: '2020 - Games Industry',
    event: 'Accepted the role of QA Tester at Tag Games',
    image: 'Images/taggames.png',
  },
  {
    year: '2021 - Finance Industry',
    event: 'Accepted the role of Software Developer at DotSys',
    image: 'Images/dotsys.png',
  },
  {
    year: '2021 - VR PhD Scholarship',
    event: 'Offered Fully funded Research PhD at Queens University via the Lambrinudi Scholarship.',
    image: 'Images/QUB.png',
  },
  {
    year: 'TBD 2024 - PhD Graduation',
    event: 'Exploring the impact of Virtual Reality on Health and social care students (Medicine, Midwifery, Social Work). Creating real-life simulations to help teach practical skills such as a Home Visit.',
    image: 'Images/gradday.jpg',
  },
];

const Timeline = () => {
  return (
    <div className="timeline-container">
      <div className="timeline-line"></div>
      {timelineData.map((item, index) => {
        const content = (
          <div className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
            <div className="content">
              <div className="timeline-year">{item.year}</div>
              <div className="timeline-event">{item.event}</div>
              {item.image && <img src={item.image} alt={`${item.year} event`} className="timeline-image" />}
              {item.video && (
                <div className="timeline-video">
                  <iframe
                    width="560"
                    height="315"
                    src={item.video}
                    title={`${item.year} event video`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              {item.morelink && (
                <div className="timeline-morelink">
                  <a href={item.morelink} target="_blank" rel="noopener noreferrer">More Info</a>
                </div>
              )}
            </div>
          </div>
        );

        if (index === 0) {
          return content; // Return without wrapping the first item
        }

        return <FadeInDiv key={index}>{content}</FadeInDiv>; // Wrap other items with FadeInDiv
      })}
    </div>
  );
};

export default Timeline;
