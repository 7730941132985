import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ProjectCard.css';

const projectData = [
  {
    title: 'Skyscanner Project',
    media: [
      { type: 'image', src: 'Images/healthdome1.png' },
      { type: 'image', src: 'Images/healthdome2.png' },
      { type: 'image', src: 'Images/healthdome3.png' },
    ],
    link: 'https://zeno.computing.dundee.ac.uk/2019-projects/team2/site/healthdometrial.php',
  },
  {
    title: 'Teaching Music in VR - Open world music exploration',
    media: [
        { type: 'image', src: 'Images/musicinvr1.png' },
        { type: 'image', src: 'Images/musicinvr2.png' },
      
    ],
    
  },
  {
    title: 'VR-Based learning trailer',
    media: [
      { type: 'video', src: 'https://www.youtube.com/embed/8VHifH0IJTE' },
      
    ],
    
  },
  // Add more projects here
];

const ProjectCard = () => {
  return (
    <div className="project-cards-container">
      {projectData.map((project, index) => (
        <div className="project-card" key={index}>
          <h2>{project.title}</h2>
          <div className="project-carousel">
            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
              {project.media.map((item, mediaIndex) => (
                <div key={mediaIndex}>
                  {item.type === 'image' ? (
                    <img src={item.src} alt={`${project.title} media ${mediaIndex + 1}`} />
                  ) : (
                    <iframe
                      className="carousel-video"
                      width="100%"
                      src={item.src}
                      title={`${project.title} video ${mediaIndex + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
              ))}
            </Carousel>
          </div>
          {project.link && (
            <a href={project.link} target="_blank" rel="noopener noreferrer">See the project!</a>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProjectCard;
