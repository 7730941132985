import React from 'react';
import './Card.css';

const Card = () => {
  return (
    <div className="card">
      <img src="Images/me.png" alt="Alex Quinn" className="card-image" />
      <div className="card-content">
        <h2>Hey! I'm Alexander Quinn</h2>
        <p>An Irish PhD Candidate Working on how to get the most out of Immserive Technologies.</p>
        <p>Feel free to contact me at one of the below socials.</p>
        <div className="card-links">
    <a href="https://www.linkedin.com/in/akquinn/">
        <img src="Images/linkedin.png" height="45" width="45" />
    </a>
    <a href="https://github.com/quinnlicious">
        <img src="Images/github.png" height="45" width="45" />
    </a>
    <a href="https://twitter.com/Quinnscodes">
        <img src="Images/twitter.png" height="45" width="45" />
    </a>
</div>
<a href="Images/Alexander_Quinn_VR_Researcher.pdf" TARGET = "_blank">
<p>My CV!</p>

</a>
      </div>
    </div>
  );
};

export default Card;