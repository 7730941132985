import React from 'react';
import Card from './Card';
import Timeline from './Timeline';
import ProjectCard from './ProjectCard';
import './App.css';

function App() {
  return (
    <div className="App">
      <Card />
      <h1>My Journey so far!</h1>
      <Timeline />
      <h1>Some of my Projects!</h1>
      <ProjectCard />
    </div>
  );
}

export default App;